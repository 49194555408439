import { ChainId, Currency, CurrencyAmount, currencyEquals, ETHER, JSBI, Token } from '@safemoon/sdk'
import React, { useCallback, useContext, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens } from '../../hooks/Tokens'
import { useDefaultTokenList } from '../../state/lists/hooks'
import { useAddUserToken, useRemoveUserAddedToken } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { ButtonSecondary } from '../Button'
import Column, { AutoColumn } from '../Column'
import { RowFixed } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { FadedSpan, MenuItem } from './styleds'
import Loader from '../Loader'
import { isDefaultToken } from '../../utils'
import Tooltip from '../Tooltip'
import { isMobile } from 'react-device-detect'
import { getNativeSymbol } from '../../utils/getTokenSymbol'

function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : currency === ETHER ? 'ETHER' : ''
}

const NativeTokenIntro = ({ chainId }: { chainId: number }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[6px]">{getNativeSymbol(chainId)}</p>
    <p className="text-[13px] leading-[16px] label-text2 mb-0">
      This is the native coin on this blockchain. This coin is used to pay the network fees for every transaction.
    </p>
  </div>
)

const WrappedNativeTokenIntro = ({ symbol }: { symbol: string }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[6px]">{symbol}</p>
    <p className="text-[13px] leading-[16px] label-text2 mb-0">
      This is the wrapped version of the native coin. You can use it to swap, but you cannot pay gas fees with it. You
      may unwrap it to native coin here. Blockchain gas fees will still be required for this action.
    </p>
  </div>
)

const SFMTokenIntro = ({ chainId }: { chainId: number }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[10px]">
      SFM{' '}
      {chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
        ? '(BSC)'
        : chainId === ChainId.SEPOLIA_TESTNET || chainId === ChainId.MAINNET
        ? '(ETH)'
        : '(Polygon)'}
    </p>
    <div className="flex items-center justify-between mb-[15px]">
      <p className="mb-0 text-[13px] leading-[13px] label-text2">Date Listed</p>
      <p className="mb-0 text-[13px] leading-[13px] secondary-text">
        {chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET ? '12/12/2021' : '3/29/2023'}
      </p>
    </div>
    <div className="mb-[10px]">
      <p className="mb-0 text-[13px] leading-[13px] label-text2 mb-[8px]">Tokenomics</p>

      <div className="flex items-start justify-between">
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Buy</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">1%</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Sell</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">1%</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Transfer</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">1%</p>
        </div>
      </div>
    </div>
    <p className="text-[13px] leading-[20px] label-text2 mb-[10px]">0.5% Token and 0.5% {getNativeSymbol(chainId)}</p>
    <p className="mb-[10px] text-[13px] leading-[13px] label-text2">Social Media</p>
    <div className="flex items-center mb-[20px]">
      <a className="mr-[10px] cursor-pointer" href={'https://safemoon.com'} target="_blank" rel="noopener noreferrer">
        <img src="/images/Internet.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://twitter.com/safemoon'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/Twitter.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://discord.com/invite/safemoon'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/discord.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://www.facebook.com/SafeMoonOfficial/'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/facebook.svg" className="w-[24px]" alt="icon" />
      </a>
      <a
        className="mr-[10px] cursor-pointer"
        href={'https://www.reddit.com/r/SafeMoon/'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/reddit.svg" className="w-[24px]" alt="icon" />
      </a>
    </div>
    <a className="link text-underline" href={`https://safemoon.com`} target="_blank" rel="noopener noreferrer">
      Click to learn more
    </a>
  </div>
)

const TokenIntro = ({ currency }: { currency: any }) => (
  <div className="tooltip-wrapper">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[10px]">{currency?.symbol}</p>
    {currency?.tokenInfo?.partnerData && (
      <div className="flex items-center justify-between mb-[15px]">
        <p className="mb-0 text-[13px] leading-[13px] label-text2">Date Listed</p>
        <p className="mb-0 text-[13px] leading-[13px] secondary-text">
          {moment(currency?.tokenInfo?.partnerData?.listingDate, 'MM/DD/YYYY').format('MM/DD/YYYY')}
        </p>
      </div>
    )}
    <div className="mb-[15px]">
      <p className="mb-0 text-[13px] leading-[13px] label-text2 mb-[8px]">Tokenomics</p>

      <div className="flex items-start justify-between">
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Buy</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">
            {currency?.tokenInfo?.actualBuyFee || 0}%
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Sell</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">
            {currency?.tokenInfo?.actualSellFee || 0}%
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-0 text-[12px] leading-[12px] label-text2 mb-[4px]">Transfer</p>
          <p className="mb-0 text-[13px] leading-[13px] label-text2 yellow-text font-bold">
            {currency?.tokenInfo?.transferFee || 0}%
          </p>
        </div>
      </div>
    </div>
    {currency?.tokenInfo?.partnerData && (
      <>
        <p className="mb-[10px] text-[13px] leading-[13px] label-text2">Social Media</p>
        <div className="flex items-center mb-[20px]">
          {currency?.tokenInfo?.partnerData?.website && (
            <a
              className="mr-[10px] cursor-pointer"
              href={currency?.tokenInfo?.partnerData?.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/Internet.svg" className="w-[24px]" alt="icon" />
            </a>
          )}
          {currency?.tokenInfo?.partnerData?.twitter && (
            <a
              className="mr-[10px] cursor-pointer"
              href={currency?.tokenInfo?.partnerData?.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/Twitter.svg" className="w-[24px]" alt="icon" />
            </a>
          )}
          {currency?.tokenInfo?.partnerData?.telegram && (
            <a
              className="cursor-pointer"
              href={currency?.tokenInfo?.partnerData?.telegram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/Telegram.svg" className="w-[24px]" alt="icon" />
            </a>
          )}
        </div>
        <a
          className="link text-underline"
          href={`https://safemoon.com/partners/detail?partner=${currency?.tokenInfo?.partnerData?.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click to learn more
        </a>
      </>
    )}
  </div>
)

function CurrencyRow({ index, style, data }: any) {
  const {
    currencies,
    defaultTokens,
    allTokens,
    ETHBalance,
    selectedCurrency,
    allBalances,
    onCurrencySelect,
    otherCurrency,
    chainId,
    theme,
    showSendWithSwap,
    account,
    removeToken,
    addToken
  } = data
  const [showTooltip, setShowTooltip] = useState(false)
  const currency = currencies[index]
  const key = currencyKey(currency)
  const isDefault = isDefaultToken(defaultTokens, currency)
  const customAdded = Boolean(!isDefault && currency instanceof Token && allTokens[currency.address])
  const balance = currency === ETHER ? ETHBalance : allBalances[key]

  const zeroBalance = balance && JSBI.equal(JSBI.BigInt(0), balance.raw)

  const isSelected = Boolean(selectedCurrency && currencyEquals(currency, selectedCurrency))
  const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))

  const open = useCallback(() => setShowTooltip(true), [setShowTooltip])
  const close = useCallback(() => setShowTooltip(false), [setShowTooltip])

  return (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      disabled={isSelected}
      selected={otherSelected}
      onMouseEnter={!isMobile ? open : undefined}
      onMouseLeave={!isMobile ? close : undefined}
      onMouseOver={!isMobile ? open : undefined}
    >
      <div
        className="flex items-center justify-between flex-1"
        onClick={() => (isSelected ? null : onCurrencySelect(currency))}
      >
        <RowFixed>
          <CurrencyLogo currency={currency} size={'24px'} style={{ marginRight: '15px' }} />
          <Column>
            <Text fontWeight={700} color={theme.text6} fontSize={16}>
              {currency?.symbol !== 'ETH' || currency?.address ? currency?.symbol : getNativeSymbol(chainId)}
            </Text>
            <FadedSpan>
              {customAdded ? (
                <TYPE.main fontWeight={500}>
                  Added by user
                  <LinkStyledButton
                    onClick={event => {
                      event.stopPropagation()
                      if (currency instanceof Token) removeToken(chainId, currency.address)
                    }}
                  >
                    (Remove)
                  </LinkStyledButton>
                </TYPE.main>
              ) : null}
              {!isDefault && !customAdded ? (
                <TYPE.main fontWeight={500}>
                  Found by address
                  <LinkStyledButton
                    onClick={event => {
                      event.stopPropagation()
                      if (currency instanceof Token) addToken(currency)
                    }}
                  >
                    (Add)
                  </LinkStyledButton>
                </TYPE.main>
              ) : null}
            </FadedSpan>
          </Column>
        </RowFixed>
        <AutoColumn>
          {balance ? (
            <Text color={theme.inputColor} fontSize={20} fontWeight={700}>
              {zeroBalance && showSendWithSwap ? (
                <ButtonSecondary padding={'4px 8px'}>
                  <Text textAlign="center" fontWeight={500} fontSize={14} color={theme.primary1}>
                    Send With Swap
                  </Text>
                </ButtonSecondary>
              ) : balance ? (
                balance.toSignificant(6)
              ) : (
                '-'
              )}
            </Text>
          ) : account ? (
            <Loader />
          ) : (
            '-'
          )}
        </AutoColumn>
      </div>
      <Tooltip
        component={
          currency === ETHER ? (
            <NativeTokenIntro chainId={chainId} />
          ) : currency?.symbol === 'SFM' ? (
            <SFMTokenIntro chainId={chainId} />
          ) : currency?.symbol === 'WBNB' ||
            currency?.symbol === 'WETH' ||
            currency?.symbol === 'WMATIC' ||
            currency?.symbol === 'WPOL' ? (
            <WrappedNativeTokenIntro symbol={currency?.symbol} />
          ) : (
            <TokenIntro currency={currency} />
          )
        }
        show={showTooltip}
      >
        <div
          className={classNames('ml-[40px]', showTooltip ? 'opacity-[1]' : 'opacity-[0.5]')}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          <img src="/images/info.svg" className="w-[24px]" alt="icon" />
        </div>
      </Tooltip>
    </MenuItem>
  )
}

export default function CurrencyList({
  currencies,
  allBalances,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  showSendWithSwap,
  height,
  fixed
}: {
  currencies: Currency[]
  selectedCurrency: Currency
  allBalances: { [tokenAddress: string]: CurrencyAmount }
  onCurrencySelect: (currency: Currency) => void
  otherCurrency: Currency
  showSendWithSwap?: boolean
  height?: number | string
  fixed?: boolean
}) {
  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const allTokens = useAllTokens()
  const defaultTokens = useDefaultTokenList()
  const addToken = useAddUserToken()
  const removeToken = useRemoveUserAddedToken()
  const ETHBalance = useETHBalances([account])[account]

  return (
    <FixedSizeList
      width="100%"
      height={height || 500}
      itemCount={currencies.length}
      itemSize={56}
      style={!height && { flex: '1' }}
      itemKey={index => currencyKey(currencies[index])}
      className={classNames('scroll', fixed && 'scrollFixed')}
      itemData={{
        currencies,
        defaultTokens,
        allTokens,
        ETHBalance,
        selectedCurrency,
        allBalances,
        onCurrencySelect,
        otherCurrency,
        chainId,
        theme,
        showSendWithSwap,
        fixed,
        account,
        removeToken,
        addToken
      }}
    >
      {CurrencyRow}
    </FixedSizeList>
  )
}
